import React from 'react';

import Nav from './Nav';

const AboutUs = () => {
  return (
    <div>
      <div>
        <Nav />
      </div>
    </div>
  );
};

export default AboutUs;
