import React from 'react';
import { NavLink } from 'react-router-dom';

import classes from './Nav.module.css';

const Nav = () => {
  return (
    <div className={classes.nav_container}>
      <ul className={classes.top_nav}>
        <li>
          <NavLink
            className={(navData) => (navData.isActive ? classes.active : '')}
            to="/home"
          >
            Home
          </NavLink>
        </li>
        <li>
          <NavLink
            className={(navData) => (navData.isActive ? classes.active : '')}
            to="/instantquote"
          >
            Pricing
          </NavLink>
        </li>
        <li>
          <NavLink
            className={(navData) => (navData.isActive ? classes.active : '')}
            to="/faq"
          >
            FAQ
          </NavLink>
        </li>
        <li>
          <NavLink
            className={(navData) => (navData.isActive ? classes.active : '')}
            to="/service"
          >
            Service Areas
          </NavLink>
        </li>
        <li>
          <NavLink
            className={(navData) => (navData.isActive ? classes.active : '')}
            to="/aboutus"
          >
            About Us
          </NavLink>
        </li>
      </ul>
    </div>
  );
};

export default Nav;
