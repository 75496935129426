import React, { useState, useEffect } from 'react';

import classes from './IQHouseCkbox.module.css';

const IQHouseCkbox = (props) => {
  const [ckboxImgDisp, setCkboxImgDisp] = useState(props.img1);

  // set the ckbox image to selected if it had previously been selected and the user returned back to this step.
  useEffect(() => {
    if (props.houseAreas.includes(props.desc)) {
      setCkboxImgDisp(props.img3);
    }
  }, [props.houseAreas, props.desc, props.img3]);

  const swapImg = () => {
    ckboxImgDisp !== props.img3 && setCkboxImgDisp(props.img2);
  };
  const restoreImg = () => {
    ckboxImgDisp !== props.img3 && setCkboxImgDisp(props.img1);
  };

  const ckboxHandler = () => {
    if (ckboxImgDisp === props.img3) {
      setCkboxImgDisp(props.img1);

      const tempHouseAreas = [...props.houseAreas].filter((el) => {
        return el !== props.desc;
      });

      props.setHouseAreas(tempHouseAreas);
    } else {
      setCkboxImgDisp(props.img3);

      const tempHouseAreas = [...props.houseAreas, props.desc].filter((el) => {
        return el !== null && el !== '';
      });

      props.setHouseAreas(tempHouseAreas);
    }
    props.toggleOverlay(props.imgId);
  };

  return (
    <div className={classes.ckbox_container}>
      <div>
        <img
          onMouseEnter={swapImg}
          onMouseLeave={restoreImg}
          onClick={ckboxHandler}
          src={ckboxImgDisp}
          alt={props.desc}
          className={classes.ckbox_img}
        />
      </div>
      <div className={classes.ckbox_desc}>{props.desc}</div>
    </div>
  );
};

export default IQHouseCkbox;
