import React, { Fragment } from 'react';

import classes from './BugsInfo.module.css';

import ant1 from '../assets/bugs/ant-1.png';
import ant2 from '../assets/bugs/ant-2.png';
import ant3 from '../assets/bugs/ant-3.png';
import spider1 from '../assets/bugs/spider-1.png';
import spider2 from '../assets/bugs/spider-2.png';
import spider3 from '../assets/bugs/spider-3.png';
import wasp1 from '../assets/bugs/wasp-1.png';
import wasp2 from '../assets/bugs/wasp-2.png';
import wasp3 from '../assets/bugs/wasp-3.png';
import roach1 from '../assets/bugs/roach-1.png';
import roach2 from '../assets/bugs/roach-2.png';
import roach3 from '../assets/bugs/roach-3.png';
import mosquito1 from '../assets/bugs/mosquito-1.png';
import mosquito2 from '../assets/bugs/mosquito-2.png';
import mosquito3 from '../assets/bugs/mosquito-3.png';
import rodent1 from '../assets/bugs/rodent-1.png';
import rodent2 from '../assets/bugs/rodent-2.png';
import rodent3 from '../assets/bugs/rodent-3.png';
import crawlers1 from '../assets/bugs/crawlers-1.png';
import crawlers2 from '../assets/bugs/crawlers-2.png';
import crawlers3 from '../assets/bugs/crawlers-3.png';

import Bug from './Bug';

const antInfo = (
  <Fragment>
    <p>
      Ants will build colonies inside and outside of your home that can range in
      1000's to 100,000s of members. Ants have roles within the colony. Scouts
      and workers can be seen outside of the colony as they are searching for
      and collecting food. However, there are many ants that stay inside the
      colony to work on the growth of the colony structure, as well as reproduce
      with the queen.
    </p>
    <p>&nbsp;</p>
    <p>
      Eliminating ants can be difficult since many of them are hidden inside the
      colony. It is important to take the right steps to eliminate the entire
      colony. Pest Control Pros know how to identify the types of ants in the
      colony and how to best eliminate them including the queen. When you see
      ants in or around your home it is best to act quickly as their colonies
      grow rapidly.
    </p>
  </Fragment>
);

const spiderInfo = (
  <Fragment>
    <p>
      Spiders are among the least desired pests that people generally do not
      want to find in their home or in outside living areas. Many spiders are
      also attracted to water and will get into swimming pools. Most spiders are
      not dangerous, but infestations can cause anxiety and indicate exposure to
      other kinds of undesirable pests. Some spiders can be poisonous and pose
      health problems. No one enjoys removing and cleaning up spider webs.
    </p>
    <p>&nbsp;</p>
    <p>
      Putting up an exterior barrier will help keep spiders out of the house and
      from infesting your roof eaves, porches, patios, garages, pools, and
      corners. If an infestation occurs inside your home, you will generally
      find them under beds, sinks, tables, and couches. Regular professional
      treatments can protect your home by drastically reducing and often
      eliminating spider infestations.
    </p>
  </Fragment>
);

const waspInfo = (
  <Fragment>
    <p>
      One of the most dreaded pests are Wasps. Once they have established a nest
      on your property, they become territorial and can be vicious if they feel
      your presence is a threat. They generally create their nests up in eaves
      and exterior corners. They also can find their way into your attic and
      home through openings such as exterior lighting fixtures or unsealed
      exterior seams and doors. No one likes to be stung, especially if one has
      an allergy to wasps.
    </p>
    <p>&nbsp;</p>
    <p>
      There are products available that can be used to evacuate wasp nests and
      remove them. However, you must do this carefully as not to suffer a wasp
      attack in the process. To ensure a safe, effective, and long-term result,
      we recommend that you have the nest professionally removed.
    </p>
  </Fragment>
);

const roachInfo = (
  <Fragment>
    <p>
      Most people tend to freak out when they see a cockroach in their home.
      Cockroaches are messy and will infest furniture, cabinets, food prep areas
      or any other dark place they can find to hide and breed. They contaminate
      food, resist insecticides, and may trigger allergies and Asthma. This type
      of infestation can spread rapidly and be hard to eradicate. It is
      generally not a one-time treatment, but an ongoing process for a period of
      time.
    </p>
    <p>&nbsp;</p>
    <p>
      Pest control technicians are trained to assess and treat all types of
      cockroach problems. Because every home and situation are different, they
      will design a unique program to effectively address your situation.
    </p>
  </Fragment>
);

const mosquitoInfo = (
  <Fragment>
    <p>
      Generally, mosquitos start breeding in the early spring and continue
      throughout the summer into late fall. They tend to be the most annoying in
      the early and late evening when you most want to enjoy time outdoors.
      Mosquito bites are itchy and at times carry harmful diseases including
      dengue, West Nile virus, Zika Virus malaria and yellow fever. You will
      find concentrations of them around anything containing standing water,
      gutters, potted plants, or wet debris.
    </p>
    <p>&nbsp;</p>
    <p>
      There are products you can purchase to help repel and kill mosquitos if
      you have a lite infestation on your property. But for larger
      concentrations, regular professional treatments during spring, summer and
      fall will be the most effective. A trained technician will visit your
      property to assess and come up with the best treatment for your situation.
    </p>
  </Fragment>
);

const rodentInfo = (
  <Fragment>
    <p>
      Rat or mouse remnants can be found in more than 80% of homes in the United
      States. Rodents generally rely on humans to survive by scavenging food
      from their homes and properties. They also find warm shelter during the
      cold months inside people's homes, often living inside their walls. They
      will reproduce and make your home theirs if they are allowed to stay.
    </p>
    <p>&nbsp;</p>
    <p>
      Removing rodents from your home or property is a process as a single
      female can reproduce 32 to 56 pups each year. Working with a professional
      who can identify how and where to trap and remove the rodents over a
      period is the most effective way to solve a rodent problem. The technician
      will visit your home on a regular basis to check baits and traps and make
      sure they remain effective.
    </p>
  </Fragment>
);

const crawlersInfo = (
  <Fragment>
    <p>
      Creepy Crawlers represent a number of pests. In this section we will
      address Centipedes and Millipedes. They are generally harmless but can be
      annoying and not wanted in your home. Millipedes secrete defensive fluids
      that can irritate skin, especially your eyes. Centipede bites are not
      really considered bites, they are more like pinches. There is a venom that
      they inject into your skin that can cause irritation much like a bee
      sting.
    </p>
    <p>&nbsp;</p>
    <p>
      These “creepy crawlers” among many others can be eliminated and repelled
      to protect your home for damage and discomfort. Signing up to have a
      certified pest technician professionally treat your home for these pests
      on a regular basis can bring peace of mind.
    </p>
  </Fragment>
);

const BugsInfo = () => {
  return (
    <div className={classes.container}>
      <div>
        <Bug
          bugImg1={ant1}
          bugImg2={ant2}
          bugImg3={ant3}
          bugTitle="Ants"
          bugInfo={antInfo}
        />
      </div>
      <div>
        <Bug
          bugImg1={spider1}
          bugImg2={spider2}
          bugImg3={spider3}
          bugTitle="Spiders"
          bugInfo={spiderInfo}
        />
      </div>
      <div>
        <Bug
          bugImg1={wasp1}
          bugImg2={wasp2}
          bugImg3={wasp3}
          bugTitle="Wasps"
          bugInfo={waspInfo}
        />
      </div>
      <div>
        <Bug
          bugImg1={roach1}
          bugImg2={roach2}
          bugImg3={roach3}
          bugTitle="Cockroaches"
          bugInfo={roachInfo}
        />
      </div>
      <div>
        <Bug
          bugImg1={mosquito1}
          bugImg2={mosquito2}
          bugImg3={mosquito3}
          bugTitle="Mosquitos"
          bugInfo={mosquitoInfo}
        />
      </div>
      <div>
        <Bug
          bugImg1={rodent1}
          bugImg2={rodent2}
          bugImg3={rodent3}
          bugTitle="Rodents"
          bugInfo={rodentInfo}
        />
      </div>
      <div>
        <Bug
          bugImg1={crawlers1}
          bugImg2={crawlers2}
          bugImg3={crawlers3}
          bugTitle="Creepy Crawlers"
          bugInfo={crawlersInfo}
        />
      </div>
    </div>
  );
};

export default BugsInfo;
