import React, { Fragment, useState } from 'react';
import db from '../firebase';
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';

import classes from './IQPersonalInfo.module.css';
import navClasses from './IQNav.module.css';
import useInput from '../hooks/use-input-PersonalInfo';

import buttonBack1 from '../assets/buttons/back_1.png';
import buttonBack2 from '../assets/buttons/back_2.png';
import houseImg from '../assets/personal_info.jpg';
import house2Img from '../assets/personal_info2.jpg';

const IQPersonalInfo = (props) => {
  const [formSubErr, setFormSubErr] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const prevPage = () => {
    props.setDivDisplay('Options');
  };

  //***--- BEGIN :: SET FUNCTIONS THAT CAN BE PASSED INTO THE HOOK TO VALIDATE FORM FIELDS
  const valueValidateNotEmpty = (value) => value.trim() !== '';
  const valueValidatePhoneNumber = (value) => value.trim().length >= 14;

  const emailPattern =
    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
  const valueValidateEmail = (value) => emailPattern.test(value);

  //const valueValidateTrue = (value) => value.trim() !== '' || value === '';
  //***--- END :: SET FUNCTIONS THAT CAN BE PASSED INTO THE HOOK TO VALIDATE FORM FIELDS

  //***--- BEGIN :: USE INPUT HOOK TO SETUP VALUES AND FUNCTIONS FOR EACH INPUT FIELD
  // We are passing the state variables as established in "InstantQuote.js" instead of creating the Stateful variable in the hook itself.
  const {
    isValid: firstNameIsValid,
    hasError: firstNameInputHasError,
    valueChangeHandler: firstNameChangedHandler,
    inputBlurHandler: firstNameBlurHandler,
  } = useInput(
    valueValidateNotEmpty,
    'text',
    props.firstName,
    props.setFirstName
  );

  const {
    isValid: lastNameIsValid,
    hasError: lastNameInputHasError,
    valueChangeHandler: lastNameChangedHandler,
    inputBlurHandler: lastNameBlurHandler,
  } = useInput(
    valueValidateNotEmpty,
    'text',
    props.lastName,
    props.setLastName
  );

  const {
    isValid: address1IsValid,
    hasError: address1InputHasError,
    valueChangeHandler: address1ChangedHandler,
    inputBlurHandler: address1BlurHandler,
  } = useInput(
    valueValidateNotEmpty,
    'text',
    props.address1,
    props.setAddress1
  );

  const {
    hasError: address2InputHasError,
    valueChangeHandler: address2ChangedHandler,
    inputBlurHandler: address2BlurHandler,
  } = useInput(
    valueValidateNotEmpty,
    'text',
    props.address2,
    props.setAddress2
  );

  const {
    isValid: cityIsValid,
    hasError: cityInputHasError,
    valueChangeHandler: cityChangedHandler,
    inputBlurHandler: cityBlurHandler,
  } = useInput(valueValidateNotEmpty, 'text', props.city, props.setCity);

  const {
    isValid: stateIsValid,
    hasError: stateInputHasError,
    valueChangeHandler: stateChangedHandler,
    inputBlurHandler: stateBlurHandler,
  } = useInput(valueValidateNotEmpty, 'text', props.state, props.setState);

  const {
    isValid: zipIsValid,
    hasError: zipInputHasError,
    valueChangeHandler: zipChangedHandler,
    inputBlurHandler: zipBlurHandler,
  } = useInput(valueValidateNotEmpty, 'text', props.zip, props.setZip);

  const {
    isValid: phoneIsValid,
    hasError: phoneInputHasError,
    valueChangeHandler: phoneChangedHandler,
    inputBlurHandler: phoneBlurHandler,
  } = useInput(valueValidatePhoneNumber, 'phone', props.phone, props.setPhone);

  const {
    isValid: emailIsValid,
    hasError: emailInputHasError,
    valueChangeHandler: emailChangedHandler,
    inputBlurHandler: emailBlurHandler,
  } = useInput(valueValidateEmail, 'text', props.email, props.setEmail);
  //***--- END :: USE INPUT HOOK TO SETUP VALUES AND FUNCTIONS FOR EACH INPUT FIELD

  //***--- BEGIN :: CHECK FIELD VALIDITY
  const firstNameInputValidity = firstNameInputHasError ? 'invalid' : 'valid';
  const lastNameInputValidity = lastNameInputHasError ? 'invalid' : 'valid';
  const address1InputValidity = address1InputHasError ? 'invalid' : 'valid';
  const address2InputValidity = address2InputHasError ? 'valid' : 'valid';
  const cityInputValidity = cityInputHasError ? 'invalid' : 'valid';
  const stateInputValidity = stateInputHasError ? 'invalid' : 'valid';
  const zipInputValidity = zipInputHasError ? 'invalid' : 'valid';
  const phoneInputValidity = phoneInputHasError ? 'invalid' : 'valid';
  const emailInputValidity = emailInputHasError ? 'invalid' : 'valid';
  //***--- END :: CHECK FIELD VALIDITY

  //***--- BEGIN :: CHECK FORM VALIDITY
  let formIsValid = false;
  if (
    firstNameIsValid &&
    lastNameIsValid &&
    address1IsValid &&
    cityIsValid &&
    stateIsValid &&
    zipIsValid &&
    phoneIsValid &&
    emailIsValid
  ) {
    formIsValid = true;
  }
  //***--- END :: CHECK FORM VALIDITY

  //***--- BEGIN :: WRITE INSTANT QUOTE INFO TO FIRESTORE DB
  const writeCallback = async () => {
    try {
      const collectionRef = collection(db, 'InstantQuotes');
      const payload = {
        customer_info: {
          firstname: props.firstName,
          lastname: props.lastName,
          address1: props.address1,
          ...(props.address2.length > 0 && {
            address2: props.address2,
          }),
          city: props.city,
          state: props.state,
          zip: props.zip,
          phone: props.phone,
          email: props.email,
        },
        ...(props.noticingGeneral.length === 0 &&
        props.noticingOther.length === 0 &&
        props.houseAreas.length === 0 &&
        props.houseOther.length === 0 &&
        props.houseSqFt.length === 0
          ? ''
          : {
              property_info: {
                ...(props.noticingGeneral.length && {
                  noticing_general: props.noticingGeneral,
                }),
                ...(props.noticingOther.length > 0 && {
                  noticing_other: props.noticingOther,
                }),
                ...(props.houseAreas.length && {
                  house_areas: props.houseAreas,
                }),
                ...(props.houseOther.length > 0 && {
                  house_other: props.houseOther,
                }),
                ...(props.houseSqFt.length > 0 && {
                  house_sqft: props.houseSqFt,
                }),
              },
            }),

        package_info: {
          package_type: props.packageType,
          ...(props.addOns.length && {
            add_ons: props.addOns,
          }),
        },

        ...(props.treatmentDay.length === 0 && props.treatmentTime.length === 0
          ? ''
          : {
              schedule_time_requested: {
                ...(props.treatmentDay.length > 0 && {
                  treatment_date: props.treatmentDay,
                }),
                ...(props.treatmentTime.length > 0 && {
                  treatment_time: props.treatmentTime,
                }),
              },
            }),

        timestamp: serverTimestamp(),
      };
      //console.log(payload);
      const response = await addDoc(collectionRef, payload);
      console.log('Firestore ID: ', response.id);
    } catch (error) {
      console.log(error);
    }
  };
  //***--- END :: WRITE INSTANT QUOTE INFO TO FIRESTORE DB

  //***--- BEGIN :: WRITE INSTANT QUOTE INFO TO FIRESTORE DB MailOut DB FOR FIRESTORE EXTENSION TO SEND OUT THE EMAIL
  const writeMailOut = async (mailSubject, mailHTML) => {
    try {
      const collectionRef = collection(db, 'mailout');
      const payload = {
        to: 'pestadvocates@odyweb.com',
        message: {
          subject: mailSubject,
          html: mailHTML,
        },
      };
      const response = await addDoc(collectionRef, payload);
      console.log('Firestore ID: ', response.id);
    } catch (error) {
      console.log(error);
      setFormSubErr(true);
    }
  };
  //***--- END :: WRITE INSTANT QUOTE INFO TO FIRESTORE DB MailOut DB FOR FIRESTORE EXTENSION TO SEND OUT THE EMAIL

  //***--- BEGIN :: SUBMIT ALL INFO FOR QUOTE
  const formSubmissionHandler = (event) => {
    event.preventDefault();

    // Check to see if the form is valid -- if not then stop
    if (!formIsValid) {
      console.log('Form is not valid');
      return;
    }

    // Write Instant Quote to the DB
    const qresponse = writeCallback();
    console.log(qresponse);

    // Prepare HTML for the Email and call the function to write to the EmailDB to send email
    const mailSubject =
      'Pest Advocates - Instant Quote (' +
      props.firstName +
      ' ' +
      props.lastName +
      ')';
    const mailHTML =
      '<p>Name: ' +
      props.firstName +
      ' ' +
      props.lastName +
      '</p><p>Address: ' +
      '</p><p>' +
      props.address1 +
      '</p><p>' +
      props.address2 +
      '</p><p>' +
      props.city +
      ', ' +
      props.state +
      ' ' +
      props.zip +
      '</p><p>Phone: ' +
      props.phone +
      '</p><p>Email: ' +
      props.email +
      '</p><p>Noticing: ' +
      (props.noticingGeneral.length === 0
        ? 'No info selected'
        : props.noticingGeneral.join(' / ')) +
      '</p><p>Noticing Other: ' +
      (props.noticingOther.length === 0
        ? 'No info selected'
        : props.noticingOther.join(' / ')) +
      '</p><p>House Areas: ' +
      (props.houseAreas.length === 0
        ? 'No info selected'
        : props.houseAreas.join(' / ')) +
      '</p><p>House Areas Other: ' +
      (props.houseOther.length === 0 ? 'No info selected' : props.houseOther) +
      '</p><p>House Square Footage: ' +
      (props.houseSqFt.length === 0 ? 'No info selected' : props.houseSqFt) +
      '</p><p>Package Type: ' +
      props.packageType +
      '</p><p>Package - Add-Ons: ' +
      (props.addOns.length === 0
        ? 'No info selected'
        : props.addOns.join(' / ')) +
      '</p><p>Treatment Date: ' +
      (props.treatmentDay.length === 0
        ? 'No info selected'
        : props.treatmentDay) +
      '</p><p>Treatment Time: ' +
      (props.treatmentTime.length === 0
        ? 'No info selected'
        : props.treatmentTime) +
      '</p>';
    const mailResponse = writeMailOut(mailSubject, mailHTML);
    console.log(mailResponse);

    setFormSubmitted(true);
    !formSubErr && props.setDivDisplay('ConfirmationSubmitted');
  };
  //***--- END :: SUBMIT ALL INFO FOR QUOTE

  return (
    <Fragment>
      <div className={navClasses.section_question}>
        Last Step to Schedule your Service
      </div>
      <div className={navClasses.section_title}>
        Contact / Location Information:
      </div>
      <div className={classes.container}>
        <div className={classes.eco_house}>
          <img src={houseImg} alt="House" className={classes.house_img1} />
          <img src={house2Img} alt="House" className={classes.house_img2} />
        </div>
        <div className={classes.form_container}>
          <form onSubmit={formSubmissionHandler}>
            <div className={classes.form}>
              <div className={classes.form_group}>
                <input
                  type="text"
                  id="firstName"
                  placeholder="First Name"
                  onChange={firstNameChangedHandler}
                  onBlur={firstNameBlurHandler}
                  value={props.firstName} // this binds "State" to this field
                  className={`${classes.form_input} ${classes[firstNameInputValidity]}`}
                />
                <label htmlFor="firstName" className={classes.form_label}>
                  First Name
                </label>
              </div>
              <div className={classes.form_group}>
                <input
                  type="text"
                  id="lastName"
                  placeholder="Last Name"
                  onChange={lastNameChangedHandler}
                  onBlur={lastNameBlurHandler}
                  value={props.lastName} // this binds "State" to this field
                  className={`${classes.form_input} ${classes[lastNameInputValidity]}`}
                />
                <label htmlFor="lastName" className={classes.form_label}>
                  Last Name
                </label>
              </div>
              <div className={classes.form_group}>
                <input
                  type="text"
                  id="address1"
                  placeholder="Address - Line 1"
                  autoComplete="on"
                  onChange={address1ChangedHandler}
                  onBlur={address1BlurHandler}
                  value={props.address1} // this binds "State" to this field
                  className={`${classes.form_input} ${classes[address1InputValidity]}`}
                />
                <label htmlFor="address1" className={classes.form_label}>
                  Address - Line 1
                </label>
              </div>
              <div className={classes.form_group}>
                <input
                  type="text"
                  id="address2"
                  placeholder="Address - Line 2"
                  autoComplete="on"
                  onChange={address2ChangedHandler}
                  onBlur={address2BlurHandler}
                  value={props.address2} // this binds "State" to this field
                  className={`${classes.form_input} ${classes[address2InputValidity]}`}
                />
                <label htmlFor="address2" className={classes.form_label}>
                  Address - Line 2
                </label>
              </div>
              <div className={classes.form_group}>
                <input
                  type="text"
                  id="city"
                  placeholder="City"
                  autoComplete="on"
                  onChange={cityChangedHandler}
                  onBlur={cityBlurHandler}
                  value={props.city} // this binds "State" to this field
                  className={`${classes.form_input} ${classes[cityInputValidity]}`}
                />
                <label htmlFor="city" className={classes.form_label}>
                  City
                </label>
              </div>
              <div className={classes.form_group}>
                <select
                  id="state"
                  placeholder="State"
                  autoComplete="on"
                  onChange={stateChangedHandler}
                  onBlur={stateBlurHandler}
                  value={props.state} // this binds "State" to this field
                  className={`${classes.form_input} ${classes[stateInputValidity]}`}
                >
                  <option value={props.state} defaultValue>
                    {props.state}
                  </option>
                  <option value="TX">TX</option>
                  <option value="UT">UT</option>
                </select>
                <label
                  htmlFor="state"
                  autoComplete="on"
                  className={classes.form_label}
                >
                  State
                </label>
              </div>
              <div className={classes.form_group}>
                <input
                  type="text"
                  id="zip"
                  placeholder="Zip Code"
                  autoComplete="on"
                  onChange={zipChangedHandler}
                  onBlur={zipBlurHandler}
                  value={props.zip} // this binds "State" to this field
                  className={`${classes.form_input} ${classes[zipInputValidity]}`}
                />
                <label htmlFor="zip" className={classes.form_label}>
                  Zip Code
                </label>
              </div>
              <div className={classes.form_group}>
                <input
                  type="text"
                  id="phone"
                  placeholder="Phone Number"
                  autoComplete="on"
                  onChange={phoneChangedHandler}
                  onBlur={phoneBlurHandler}
                  value={props.phone}
                  className={`${classes.form_input} ${classes[phoneInputValidity]}`}
                />
                <label htmlFor="phone" className={classes.form_label}>
                  Phone Number
                </label>
              </div>
              <div className={classes.form_group}>
                <input
                  type="text"
                  id="email"
                  placeholder="Email Address"
                  autoComplete="on"
                  onChange={emailChangedHandler}
                  onBlur={emailBlurHandler}
                  value={props.email}
                  className={`${classes.form_input} ${classes[emailInputValidity]}`}
                />
                <label htmlFor="email" className={classes.form_label}>
                  Email Address
                </label>
              </div>
              <div className={classes.form_group}>
                <div className={classes.button_container}>
                  <button disabled={!formIsValid && !formSubmitted}>
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className={navClasses.section_nav}>
        <div className={navClasses.section_nav_left}>
          <img
            alt="back"
            src={buttonBack1}
            className={navClasses.section_nav_button}
            onClick={prevPage}
            onMouseOver={(e) => (e.currentTarget.src = buttonBack2)}
            onMouseOut={(e) => (e.currentTarget.src = buttonBack1)}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default IQPersonalInfo;
