import React, { Fragment, useState } from 'react';

import useInput from '../hooks/use-input';
import Modal from './UI/Modal';
import DatePicker from 'react-datepicker';
import setHours from 'date-fns/setHours';
import setMinutes from 'date-fns/setMinutes';
import classes from './HomeCallbackTime.module.css';
import './DatePicker.css';
import 'react-datepicker/dist/react-datepicker.css';
import db from '../firebase';
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';

const HomeCallbackTime = (props) => {
  const [modalIsShown, setModalIsShown] = useState(false);
  const [formSubErr, setFormSubErr] = useState(false);
  const [startDate, setStartDate] = useState(new Date());

  const showModalHandler = () => {
    setModalIsShown(true);
  };
  const hideModalHandler = () => {
    setModalIsShown(false);
  };

  const modalContent = (
    <Fragment>
      <div className={classes.modal_main}>
        <div className={classes.modal_header}>
          <div className={classes.div_grow}></div>
          <div>
            <button className={classes.modal_button} onClick={hideModalHandler}>
              X
            </button>
          </div>
        </div>
        <div className={classes.modal_content}>
          {formSubErr ? (
            <p>Sorry, There has been an error</p>
          ) : (
            <p>
              Thank you for your submission!<br></br>We have received your
              contact request and will respond based on the time you have
              indicated.
            </p>
          )}
        </div>
      </div>
    </Fragment>
  );

  const valueValidateNotEmpty = (value) => value.trim() !== '';
  const valueValidatePhoneNumber = (value) => value.trim().length >= 14;
  /*
  const pattern =
    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
  const valueValidateEmail = (value) => pattern.test(value);
*/
  const valueValidateTrue = (value) => value.trim() !== '' || value === '';

  const {
    value: enteredName,
    isValid: enteredNameIsValid,
    hasError: nameInputHasError,
    valueChangeHandler: nameChangedHandler,
    inputBlurHandler: nameBlurHandler,
    reset: resetNameInput,
  } = useInput(valueValidateNotEmpty);

  const {
    value: enteredContact,
    isValid: enteredContactIsValid,
    hasError: contactInputHasError,
    valueChangeHandler: contactChangedHandler,
    inputBlurHandler: contactBlurHandler,
    reset: resetContactInput,
  } = useInput(valueValidatePhoneNumber, 'phone');

  const {
    value: enteredMessage,
    hasError: messageInputHasError,
    valueChangeHandler: messageChangedHandler,
    inputBlurHandler: messageBlurHandler,
    reset: resetMessageInput,
  } = useInput(valueValidateTrue);

  const {
    value: enteredHoneypot,
    valueChangeHandler: honeypotChangedHandler,
    inputBlurHandler: honeypotBlurHandler,
    reset: resetHoneypotInput,
  } = useInput(valueValidateTrue);

  let formIsValid = false;
  if (enteredNameIsValid && enteredContactIsValid) {
    formIsValid = true;
  }

  const writeCallback = async () => {
    try {
      const collectionRef = collection(db, 'callbacks');
      const payload = {
        name: enteredName,
        contact: enteredContact,
        message: enteredMessage,
        callbacktime: startDate,
        timestamp: serverTimestamp(),
      };
      const response = await addDoc(collectionRef, payload);
      console.log('Firestore ID: ', response.id);
    } catch (error) {
      console.log(error);
    }
  };

  const writeMailOut = async (mailSubject, mailHTML) => {
    try {
      const collectionRef = collection(db, 'mailout');
      const payload = {
        to: 'pestadvocates@odyweb.com',
        message: {
          subject: mailSubject,
          html: mailHTML,
        },
      };
      const response = await addDoc(collectionRef, payload);
      console.log('Firestore ID: ', response.id);
    } catch (error) {
      console.log(error);
      setFormSubErr(true);
    }
  };

  const formSubmissionHandler = (event) => {
    event.preventDefault();

    if (enteredHoneypot !== '') {
      //console.log('honeypot has a value so No-Go');
      return;
    }

    if (!formIsValid) {
      console.log('Form is not valid');
      return;
    }

    //console.log(enteredName);
    //console.log(enteredContact);
    //console.log(enteredMessage);

    const qresponse = writeCallback();
    console.log('qresponse: ', qresponse);

    const mailSubject = 'Pest Advocates - Callback (' + enteredName + ')';
    const mailHTML =
      '<p>Name: ' +
      enteredName +
      '</p><p>Contact Info: ' +
      enteredContact +
      '</p><p>Callback Requested Time: ' +
      startDate +
      '</p><p>Message: ' +
      enteredMessage +
      '</p>';
    const mailResponse = writeMailOut(mailSubject, mailHTML);
    console.log(mailResponse);

    showModalHandler();

    setFormSubErr(false);

    resetNameInput();
    resetContactInput();
    resetMessageInput();
    resetHoneypotInput();
  };

  const nameInputValidity = nameInputHasError ? 'invalid' : 'valid';
  const contactInputValidity = contactInputHasError ? 'invalid' : 'valid';
  const messageInputValidity = messageInputHasError ? 'valid' : 'valid';

  return (
    <Fragment>
      <div className={classes.container}>
        <h3>Set a time for us to call you</h3>
        <form onSubmit={formSubmissionHandler}>
          <div className={classes.form}>
            <div className={classes.form_group}>
              <input
                type="text"
                id="name"
                placeholder="Your Name"
                onChange={nameChangedHandler}
                onBlur={nameBlurHandler}
                value={enteredName} // this binds "State" to this field
                className={`${classes.form_input} ${classes[nameInputValidity]}`}
              />
              <label htmlFor="name" className={classes.form_label}>
                Your Name
              </label>
            </div>
            <div className={classes.form_group}>
              <input
                type="text"
                id="contact"
                placeholder="Your Phone"
                onChange={contactChangedHandler}
                onBlur={contactBlurHandler}
                value={enteredContact} // this binds "State" to this field
                className={`${classes.form_input} ${classes[contactInputValidity]}`}
              />
              <label htmlFor="contact" className={classes.form_label}>
                Your Phone
              </label>
            </div>
            <div className={classes.form_group}>
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                dateFormat="MM/dd/yyyy h:mm aa"
                id="datepickerdropdown"
                showTimeSelect
                excludeTimes={[
                  setHours(setMinutes(new Date(), 0), 0),
                  setHours(setMinutes(new Date(), 30), 0),
                  setHours(setMinutes(new Date(), 0), 1),
                  setHours(setMinutes(new Date(), 30), 1),
                  setHours(setMinutes(new Date(), 0), 2),
                  setHours(setMinutes(new Date(), 30), 2),
                  setHours(setMinutes(new Date(), 0), 3),
                  setHours(setMinutes(new Date(), 30), 3),
                  setHours(setMinutes(new Date(), 0), 4),
                  setHours(setMinutes(new Date(), 30), 4),
                  setHours(setMinutes(new Date(), 0), 5),
                  setHours(setMinutes(new Date(), 30), 5),
                  setHours(setMinutes(new Date(), 0), 6),
                  setHours(setMinutes(new Date(), 30), 6),
                  setHours(setMinutes(new Date(), 0), 7),
                  setHours(setMinutes(new Date(), 30), 7),
                  setHours(setMinutes(new Date(), 0), 8),
                  setHours(setMinutes(new Date(), 30), 8),
                  setHours(setMinutes(new Date(), 0), 21),
                  setHours(setMinutes(new Date(), 30), 21),
                  setHours(setMinutes(new Date(), 0), 22),
                  setHours(setMinutes(new Date(), 30), 22),
                  setHours(setMinutes(new Date(), 0), 23),
                  setHours(setMinutes(new Date(), 30), 23),
                ]}
              />
              <label
                htmlFor="datepickerdropdown"
                className={classes.form_label}
              >
                Desired Callback Date and Time
              </label>
            </div>
            <div className={classes.form_group}>
              <textarea
                rows="7"
                id="message"
                placeholder="Questions or Information"
                onChange={messageChangedHandler}
                onBlur={messageBlurHandler}
                value={enteredMessage} // this binds "State" to this field
                className={`${classes.form_input} ${classes[messageInputValidity]}`}
              ></textarea>
              <label htmlFor="message" className={classes.form_label}>
                Questions or Information
              </label>
            </div>
            <div className={classes.honeypot}>
              <input
                type="email"
                id="honeypot"
                autoComplete="off"
                onChange={honeypotChangedHandler}
                onBlur={honeypotBlurHandler}
                value={enteredHoneypot} // this binds "State" to this field
              />
              <label htmlFor="email">Email</label>
            </div>
          </div>
          <div className={classes.button_container}>
            <button disabled={!formIsValid}>Submit</button>
          </div>
        </form>
      </div>
      {modalIsShown && <Modal onClose={hideModalHandler}>{modalContent}</Modal>}
    </Fragment>
  );
};

export default HomeCallbackTime;
