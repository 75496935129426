import React from 'react';

import Nav from './Nav';

function ServiceAreas() {
  return (
    <div>
      <div>
        <Nav />
      </div>
    </div>
  );
}

export default ServiceAreas;
