import React, { Fragment, useState, useEffect } from 'react';

import classes from './IQHouse.module.css';
import navClasses from './IQNav.module.css';
import IQHouseCkbox from './IQHouseCkbox';

import houseImg from '../assets/hlayer-house.png';
import houseImgEaves from '../assets/hlayer-eaves.png';
import houseImgEntryPoints from '../assets/hlayer-entrypoints.png';
import houseImgInterior from '../assets/hlayer-interior.png';
import houseImgLawn from '../assets/hlayer-lawn.png';
import houseImgPerimeter from '../assets/hlayer-perimeter.png';
import checkImg1 from '../assets/checkboxes/check-1.png';
import checkImg2 from '../assets/checkboxes/check-2.png';
import checkImg3 from '../assets/checkboxes/check-3.png';
import checkImg4 from '../assets/checkboxes/check-4.png';
import checkImg5 from '../assets/checkboxes/check-5.png';
import ckrolloverImg1 from '../assets/checkboxes/ckrollover-1.png';
import ckrolloverImg2 from '../assets/checkboxes/ckrollover-2.png';
import ckrolloverImg3 from '../assets/checkboxes/ckrollover-3.png';
import ckrolloverImg4 from '../assets/checkboxes/ckrollover-4.png';
import ckrolloverImg5 from '../assets/checkboxes/ckrollover-5.png';
import uncheckedImg1 from '../assets/checkboxes/unchecked-1.png';
import uncheckedImg2 from '../assets/checkboxes/unchecked-2.png';
import uncheckedImg3 from '../assets/checkboxes/unchecked-3.png';
import uncheckedImg4 from '../assets/checkboxes/unchecked-4.png';
import uncheckedImg5 from '../assets/checkboxes/unchecked-5.png';
import buttonNext1 from '../assets/buttons/next_1.png';
import buttonNext2 from '../assets/buttons/next_2.png';
import buttonBack1 from '../assets/buttons/back_1.png';
import buttonBack2 from '../assets/buttons/back_2.png';

const IQHouse = (props) => {
  const [toggleEaves, setToggleEaves] = useState(false);
  const [toggleEntry, setToggleEntry] = useState(false);
  const [toggleInterior, setToggleInterior] = useState(false);
  const [toggleLawn, setToggleLawn] = useState(false);
  const [togglePerimeter, setTogglePerimeter] = useState(false);

  useEffect(() => {
    props.houseAreas.includes('Eaves') && setToggleEaves(true);
    props.houseAreas.includes('Entry-Points / Crack & Crevices') &&
      setToggleEntry(true);
    props.houseAreas.includes('Home Interior') && setToggleInterior(true);
    props.houseAreas.includes('Lawn/Grass Areas') && setToggleLawn(true);
    props.houseAreas.includes('Home Perimeter') && setTogglePerimeter(true);
  }, [props.houseAreas]);

  // record the info entered into the Other box in the the Stateful Variable
  const handleHouseOther = (event) => {
    props.setHouseOther(event.target.value);
  };

  // determine whether an image should be displayed or hidden
  const toggleOverlay = (imgId) => {
    imgId === 'eaves' && !toggleEaves && setToggleEaves(true);
    imgId === 'eaves' && toggleEaves && setToggleEaves(false);
    imgId === 'entry' && !toggleEntry && setToggleEntry(true);
    imgId === 'entry' && toggleEntry && setToggleEntry(false);
    imgId === 'interior' && !toggleInterior && setToggleInterior(true);
    imgId === 'interior' && toggleInterior && setToggleInterior(false);
    imgId === 'lawn' && !toggleLawn && setToggleLawn(true);
    imgId === 'lawn' && toggleLawn && setToggleLawn(false);
    imgId === 'perimeter' && !togglePerimeter && setTogglePerimeter(true);
    imgId === 'perimeter' && togglePerimeter && setTogglePerimeter(false);
  };

  const prevPage = () => {
    props.setDivDisplay('General');
  };

  const nextPage = () => {
    props.setDivDisplay('SquareFootage');
  };

  return (
    <Fragment>
      <div className={navClasses.section_question}>
        Where are you noticing the Pest issues?
      </div>
      <div className={classes.container}>
        <div className={classes.img_container}>
          <img
            src={houseImg}
            alt="Home Treatments"
            className={classes.houseimage}
          />
          <img
            src={houseImgEaves}
            alt="Eaves"
            id="eaves"
            className={`${classes.houseimage_overlay} ${
              toggleEaves ? classes.img_on : classes.img_off
            }`}
          />
          <img
            src={houseImgEntryPoints}
            alt="Entry Points"
            id="entry"
            className={`${classes.houseimage_overlay} ${
              toggleEntry ? classes.img_on : classes.img_off
            }`}
          />
          <img
            src={houseImgInterior}
            alt="Interior"
            id="interior"
            className={`${classes.houseimage_overlay} ${
              toggleInterior ? classes.img_on : classes.img_off
            }`}
          />
          <img
            src={houseImgLawn}
            alt="Lawn"
            id="lawn"
            className={`${classes.houseimage_overlay} ${
              toggleLawn ? classes.img_on : classes.img_off
            }`}
          />
          <img
            src={houseImgPerimeter}
            alt="Perimeter"
            id="perimeter"
            className={`${classes.houseimage_overlay} ${
              togglePerimeter ? classes.img_on : classes.img_off
            }`}
          />
        </div>
        <div>
          <img
            src={houseImg}
            alt="Home Treatments"
            className={classes.houseimageback}
          />
        </div>
        <div className={classes.desc_container}>
          <div>
            <IQHouseCkbox
              img1={uncheckedImg1}
              img2={ckrolloverImg1}
              img3={checkImg1}
              desc="Eaves"
              houseAreas={props.houseAreas}
              setHouseAreas={props.setHouseAreas}
              toggleOverlay={toggleOverlay}
              imgId="eaves"
            />
          </div>
          <div>
            <IQHouseCkbox
              img1={uncheckedImg2}
              img2={ckrolloverImg2}
              img3={checkImg2}
              desc="Entry-Points / Crack & Crevices"
              houseAreas={props.houseAreas}
              setHouseAreas={props.setHouseAreas}
              toggleOverlay={toggleOverlay}
              imgId="entry"
            />
          </div>
          <div>
            <IQHouseCkbox
              img1={uncheckedImg3}
              img2={ckrolloverImg3}
              img3={checkImg3}
              desc="Home Perimeter"
              houseAreas={props.houseAreas}
              setHouseAreas={props.setHouseAreas}
              toggleOverlay={toggleOverlay}
              imgId="perimeter"
            />
          </div>
          <div>
            <IQHouseCkbox
              img1={uncheckedImg4}
              img2={ckrolloverImg4}
              img3={checkImg4}
              desc="Lawn/Grass Areas"
              houseAreas={props.houseAreas}
              setHouseAreas={props.setHouseAreas}
              toggleOverlay={toggleOverlay}
              imgId="lawn"
            />
          </div>
          <div>
            <IQHouseCkbox
              img1={uncheckedImg5}
              img2={ckrolloverImg5}
              img3={checkImg5}
              desc="Home Interior"
              houseAreas={props.houseAreas}
              setHouseAreas={props.setHouseAreas}
              toggleOverlay={toggleOverlay}
              imgId="interior"
            />
          </div>
          <div className={classes.container_textarea}>
            <label htmlFor="houseother">Other:</label>
            <textarea
              id="houseother"
              rows="4"
              columns="20"
              className={classes.othertextarea}
              onChange={handleHouseOther}
              defaultValue={props.houseOther}
            ></textarea>
          </div>
        </div>
      </div>

      <div className={navClasses.section_nav}>
        <div className={navClasses.section_nav_left}>
          <img
            alt="back"
            src={buttonBack1}
            className={navClasses.section_nav_button}
            onClick={prevPage}
            onMouseOver={(e) => (e.currentTarget.src = buttonBack2)}
            onMouseOut={(e) => (e.currentTarget.src = buttonBack1)}
          />
        </div>
        <div className={navClasses.div_grow}></div>
        <div className={navClasses.section_nav_right}>
          <img
            alt="next"
            src={buttonNext1}
            className={navClasses.section_nav_button}
            onClick={nextPage}
            onMouseOver={(e) => (e.currentTarget.src = buttonNext2)}
            onMouseOut={(e) => (e.currentTarget.src = buttonNext1)}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default IQHouse;
