import React from 'react';
import { NavLink } from 'react-router-dom';

import classes from './HouseTreatmentsHeader.module.css';

const HouseTreatmentsHeader = () => {
  return (
    <div className={classes.container}>
      <div className={classes.line_1}>5+ Points of Pest Protection</div>
      <div className={classes.line_2}>
        <NavLink className={classes.button_nav} to="/instantquote">
          <div className={classes.button_container}>
            <div className={classes.button_free}>FREE</div>
            <div className={classes.button_content}>
              Online Instant Quote &gt;&gt;
            </div>
          </div>
        </NavLink>
      </div>
    </div>
  );
};

export default HouseTreatmentsHeader;
