import React, { Fragment } from 'react';

import classes from './IQScheduleService.module.css';
import navClasses from './IQNav.module.css';
import RadioButton from './RadioButton';

import buttonNext1 from '../assets/buttons/next_1.png';
import buttonNext2 from '../assets/buttons/next_2.png';
import buttonBack1 from '../assets/buttons/back_1.png';
import buttonBack2 from '../assets/buttons/back_2.png';

const IQScheduleService = (props) => {
  //BEGIN :: GET THE NEXT 3 DAYS DATES
  const today = new Date();
  const todayDay = today.getDay();

  // Figure out the next 3 days excluding Sunday
  let dayOne = 1;
  let dayTwo = 2;
  let dayThree = 3;

  if (todayDay === 4) {
    dayOne = 1;
    dayTwo = 2;
    dayThree = 4;
  } else if (todayDay === 5) {
    dayOne = 1;
    dayTwo = 3;
    dayThree = 4;
  } else if (todayDay === 6) {
    dayOne = 2;
    dayTwo = 3;
    dayThree = 4;
  } else {
    dayOne = 1;
    dayTwo = 2;
    dayThree = 3;
  }

  const DateA = new Date(today);
  DateA.setDate(DateA.getDate() + dayOne);
  DateA.setHours(0, 0, 0, 0);
  const finalDateA = DateA.toLocaleDateString('en-US', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  const DateB = new Date(today);
  DateB.setDate(DateB.getDate() + dayTwo);
  DateB.setHours(0, 0, 0, 0);
  const finalDateB = DateB.toLocaleDateString('en-US', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  const DateC = new Date(today);
  DateC.setDate(DateC.getDate() + dayThree);
  DateC.setHours(0, 0, 0, 0);
  const finalDateC = DateC.toLocaleDateString('en-US', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
  // END :: GET THE NEXT 3 DAYS DATES

  const prevPage = () => {
    props.setDivDisplay('Options');
  };

  const nextPage = () => {
    props.setDivDisplay('PersonalInfo');
  };

  return (
    <Fragment>
      <div className={navClasses.section_question}>
        Initial Pest Treatment Service
      </div>
      <div className={navClasses.section_title}>
        Select a date and time of day
      </div>
      <div className={classes.container}>
        <div className={classes.container_dates}>
          <RadioButton
            optionDesc={finalDateA}
            statefulVar={props.treatmentDay}
            setStatefulVar={props.setTreatmentDay}
          />
          <RadioButton
            optionDesc={finalDateB}
            statefulVar={props.treatmentDay}
            setStatefulVar={props.setTreatmentDay}
          />
          <RadioButton
            optionDesc={finalDateC}
            statefulVar={props.treatmentDay}
            setStatefulVar={props.setTreatmentDay}
          />
        </div>
        <div className={classes.container_times_1}>
          <div className={classes.container_times}>
            <RadioButton
              optionDesc="Morning (9am - 12pm)"
              statefulVar={props.treatmentTime}
              setStatefulVar={props.setTreatmentTime}
            />
            <RadioButton
              optionDesc="Afternoon (12pm - 3pm)"
              statefulVar={props.treatmentTime}
              setStatefulVar={props.setTreatmentTime}
            />
            <RadioButton
              optionDesc="Evening (3pm - 6pm)"
              statefulVar={props.treatmentTime}
              setStatefulVar={props.setTreatmentTime}
            />
          </div>
        </div>
      </div>
      <div className={navClasses.section_nav}>
        <div className={navClasses.section_nav_left}>
          <img
            alt="back"
            src={buttonBack1}
            className={navClasses.section_nav_button}
            onClick={prevPage}
            onMouseOver={(e) => (e.currentTarget.src = buttonBack2)}
            onMouseOut={(e) => (e.currentTarget.src = buttonBack1)}
          />
        </div>
        <div className={navClasses.div_grow}></div>
        <div className={navClasses.section_nav_right}>
          <img
            alt="next"
            src={buttonNext1}
            className={navClasses.section_nav_button}
            onClick={nextPage}
            onMouseOver={(e) => (e.currentTarget.src = buttonNext2)}
            onMouseOut={(e) => (e.currentTarget.src = buttonNext1)}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default IQScheduleService;
