import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

import classes from './InstantQuote.module.css';

import IQNoticing from './IQNoticing';
import IQHouse from './IQHouse';
import IQSquareFootage from './IQSquareFootage';
import IQOptions from './IQOptions';
import IQScheduleService from './IQScheduleService';
import IQPersonalInfo from './IQPersonalInfo';

import logoImage from '../assets/logo.png';
import IQSubmitted from './IQSubmitted';

const InstantQuote = () => {
  const [noticingGeneral, setNoticingGeneral] = useState('');
  const [noticingOther, setNoticingOther] = useState('');
  const [houseAreas, setHouseAreas] = useState('');
  const [houseOther, setHouseOther] = useState('');
  const [houseSqFt, setHouseSqFt] = useState('');
  const [packageType, setPackageType] = useState(
    'General Pest 12 Month Service Contract'
  );
  const [addOns, setAddOns] = useState('');
  const [treatmentDay, setTreatmentDay] = useState('');
  const [treatmentTime, setTreatmentTime] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [address1, setAddress1] = useState('');
  const [address2, setAddress2] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zip, setZip] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [divDisplay, setDivDisplay] = useState('');

  //console.log('noticingGeneral: ', noticingGeneral);
  //console.log('noticingOther: ', noticingOther);
  //console.log(houseAreas);
  //console.log(houseOther);
  //console.log(houseSqFt);
  //console.log(packageType);
  //console.log(addOns);
  //console.log(treatmentTime);
  //console.log(treatmentDay);
  //console.log(firstName);
  //console.log(lastName);
  //console.log(address1, ' ', address2, ' ', city, ' ', state, ' ', zip);
  //console.log(phone);
  //console.log(email);

  if (divDisplay === '') {
    setDivDisplay('General');
  }

  return (
    <div className={classes.quote_container}>
      <div className={classes.header_container}>
        <div className={classes.logo_container}>
          <NavLink to="/home">
            <img src={logoImage} alt="Logo" className={classes.logo_top} />
          </NavLink>
        </div>
        <div className={classes.header_title}>Free Online Instant Quote</div>
      </div>
      <div className={classes.div_spacer}></div>
      <div className={classes.form_container}>
        {divDisplay === 'General' && (
          <div>
            <IQNoticing
              noticingGeneral={noticingGeneral}
              setNoticingGeneral={setNoticingGeneral}
              noticingOther={noticingOther}
              setNoticingOther={setNoticingOther}
              setDivDisplay={setDivDisplay}
            />
          </div>
        )}
        {divDisplay === 'House' && (
          <div>
            <IQHouse
              houseAreas={houseAreas}
              setHouseAreas={setHouseAreas}
              houseOther={houseOther}
              setHouseOther={setHouseOther}
              setDivDisplay={setDivDisplay}
            />
          </div>
        )}
        {divDisplay === 'SquareFootage' && (
          <div>
            <IQSquareFootage
              houseSqFt={houseSqFt}
              setHouseSqFt={setHouseSqFt}
              setDivDisplay={setDivDisplay}
            />
          </div>
        )}
        {divDisplay === 'Options' && (
          <div>
            <IQOptions
              packageType={packageType}
              setPackageType={setPackageType}
              addOns={addOns}
              setAddOns={setAddOns}
              setDivDisplay={setDivDisplay}
              noticingGeneral={noticingGeneral}
              noticingOther={noticingOther}
              houseSqFt={houseSqFt}
            />
          </div>
        )}
        {divDisplay === 'ScheduleService' && (
          <div>
            <IQScheduleService
              treatmentDay={treatmentDay}
              setTreatmentDay={setTreatmentDay}
              treatmentTime={treatmentTime}
              setTreatmentTime={setTreatmentTime}
              setDivDisplay={setDivDisplay}
            />
          </div>
        )}
        {divDisplay === 'PersonalInfo' && (
          <div>
            <IQPersonalInfo
              firstName={firstName}
              setFirstName={setFirstName}
              lastName={lastName}
              setLastName={setLastName}
              address1={address1}
              setAddress1={setAddress1}
              address2={address2}
              setAddress2={setAddress2}
              city={city}
              setCity={setCity}
              state={state}
              setState={setState}
              zip={zip}
              setZip={setZip}
              phone={phone}
              setPhone={setPhone}
              email={email}
              setEmail={setEmail}
              noticingGeneral={noticingGeneral}
              noticingOther={noticingOther}
              houseAreas={houseAreas}
              houseOther={houseOther}
              houseSqFt={houseSqFt}
              packageType={packageType}
              addOns={addOns}
              treatmentDay={treatmentDay}
              treatmentTime={treatmentTime}
              setDivDisplay={setDivDisplay}
            />
          </div>
        )}
        {divDisplay === 'ConfirmationSubmitted' && (
          <div>
            <IQSubmitted setDivDisplay={setDivDisplay} />
          </div>
        )}
      </div>
      <div className={classes.rights_reserved}>
        &copy; 2023 All Rights Reserved.
      </div>
    </div>
  );
};

export default InstantQuote;
