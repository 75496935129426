import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyDexgUFXi-7tPDZHfi9QoTE6Sb9ry-9xFs',
  authDomain: 'pestadvocates.firebaseapp.com',
  projectId: 'pestadvocates',
  storageBucket: 'pestadvocates.appspot.com',
  messagingSenderId: '888737518828',
  appId: '1:888737518828:web:12133bfb15c117b83841c9',
  measurementId: 'G-36N072L0Q1',
};

const firebaseApp = initializeApp(firebaseConfig);

const db = getFirestore(firebaseApp);

export default db;
