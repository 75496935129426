import React, { Fragment } from 'react';

import classes from './IQNoticing.module.css';
import navClasses from './IQNav.module.css';

import ant1 from '../assets/bugs/ant-3.png';
import ant2 from '../assets/bugs/ant-2.png';
import ant3 from '../assets/bugs/ant-4.png';
import spider1 from '../assets/bugs/spider-3.png';
import spider2 from '../assets/bugs/spider-2.png';
import spider3 from '../assets/bugs/spider-4.png';
import wasp1 from '../assets/bugs/wasp-3.png';
import wasp2 from '../assets/bugs/wasp-2.png';
import wasp3 from '../assets/bugs/wasp-4.png';
import roach1 from '../assets/bugs/roach-3.png';
import roach2 from '../assets/bugs/roach-2.png';
import roach3 from '../assets/bugs/roach-4.png';
import mosquito1 from '../assets/bugs/mosquito-3.png';
import mosquito2 from '../assets/bugs/mosquito-2.png';
import mosquito3 from '../assets/bugs/mosquito-4.png';
import rodent1 from '../assets/bugs/rodent-3.png';
import rodent2 from '../assets/bugs/rodent-2.png';
import rodent3 from '../assets/bugs/rodent-4.png';
import crawlers1 from '../assets/bugs/crawlers-3.png';
import crawlers2 from '../assets/bugs/crawlers-2.png';
import crawlers3 from '../assets/bugs/crawlers-4.png';
import centipede1 from '../assets/bugs/centipede-3.png';
import centipede2 from '../assets/bugs/centipede-2.png';
import centipede3 from '../assets/bugs/centipede-4.png';
import cricket1 from '../assets/bugs/cricket-3.png';
import cricket2 from '../assets/bugs/cricket-2.png';
import cricket3 from '../assets/bugs/cricket-4.png';
import earwig1 from '../assets/bugs/earwig-3.png';
import earwig2 from '../assets/bugs/earwig-2.png';
import earwig3 from '../assets/bugs/earwig-4.png';
import flea1 from '../assets/bugs/flea-3.png';
import flea2 from '../assets/bugs/flea-2.png';
import flea3 from '../assets/bugs/flea-4.png';
import fly1 from '../assets/bugs/fly-3.png';
import fly2 from '../assets/bugs/fly-2.png';
import fly3 from '../assets/bugs/fly-4.png';
import scorpion1 from '../assets/bugs/scorpion-3.png';
import scorpion2 from '../assets/bugs/scorpion-2.png';
import scorpion3 from '../assets/bugs/scorpion-4.png';
import silverfish1 from '../assets/bugs/silverfish-3.png';
import silverfish2 from '../assets/bugs/silverfish-2.png';
import silverfish3 from '../assets/bugs/silverfish-4.png';
import tick1 from '../assets/bugs/tick-3.png';
import tick2 from '../assets/bugs/tick-2.png';
import tick3 from '../assets/bugs/tick-4.png';
import buttonNext1 from '../assets/buttons/next_1.png';
import buttonNext2 from '../assets/buttons/next_2.png';

import IQNoticingBug from './IQNoticingBug';

const IQNoticing = (props) => {
  const nextPage = () => {
    props.setDivDisplay('House');
  };

  return (
    <Fragment>
      <div className={navClasses.section_question}>
        What Pests are you noticing?
      </div>
      <div className={navClasses.section_title}>General Pest Services</div>
      <div className={classes.container}>
        <div>
          <IQNoticingBug
            bugImg1={ant1}
            bugImg2={ant2}
            bugImg3={ant3}
            bugTitle="Ants"
            noticing={props.noticingGeneral}
            setNoticing={props.setNoticingGeneral}
          />
        </div>
        <div>
          <IQNoticingBug
            bugImg1={spider1}
            bugImg2={spider2}
            bugImg3={spider3}
            bugTitle="Spiders"
            noticing={props.noticingGeneral}
            setNoticing={props.setNoticingGeneral}
          />
        </div>
        <div>
          <IQNoticingBug
            bugImg1={wasp1}
            bugImg2={wasp2}
            bugImg3={wasp3}
            bugTitle="Wasps"
            noticing={props.noticingGeneral}
            setNoticing={props.setNoticingGeneral}
          />
        </div>
        <div>
          <IQNoticingBug
            bugImg1={roach1}
            bugImg2={roach2}
            bugImg3={roach3}
            bugTitle="Cockroaches"
            noticing={props.noticingGeneral}
            setNoticing={props.setNoticingGeneral}
          />
        </div>
        <div>
          <IQNoticingBug
            bugImg1={cricket1}
            bugImg2={cricket2}
            bugImg3={cricket3}
            bugTitle="Crickets"
            noticing={props.noticingGeneral}
            setNoticing={props.setNoticingGeneral}
          />
        </div>
        <div>
          <IQNoticingBug
            bugImg1={earwig1}
            bugImg2={earwig2}
            bugImg3={earwig3}
            bugTitle="Earwigs"
            noticing={props.noticingGeneral}
            setNoticing={props.setNoticingGeneral}
          />
        </div>
        <div>
          <IQNoticingBug
            bugImg1={silverfish1}
            bugImg2={silverfish2}
            bugImg3={silverfish3}
            bugTitle="Silverfish"
            noticing={props.noticingGeneral}
            setNoticing={props.setNoticingGeneral}
          />
        </div>
        <div>
          <IQNoticingBug
            bugImg1={centipede1}
            bugImg2={centipede2}
            bugImg3={centipede3}
            bugTitle="Centipedes"
            noticing={props.noticingGeneral}
            setNoticing={props.setNoticingGeneral}
          />
        </div>
        <div>
          <IQNoticingBug
            bugImg1={scorpion1}
            bugImg2={scorpion2}
            bugImg3={scorpion3}
            bugTitle="Scorpions"
            noticing={props.noticingGeneral}
            setNoticing={props.setNoticingGeneral}
          />
        </div>
        <div>
          <IQNoticingBug
            bugImg1={crawlers1}
            bugImg2={crawlers2}
            bugImg3={crawlers3}
            bugTitle="Creepy Crawlers"
            noticing={props.noticingGeneral}
            setNoticing={props.setNoticingGeneral}
          />
        </div>
      </div>
      <div className={navClasses.section_title}>Additional Services</div>
      <div className={classes.container}>
        <div>
          <IQNoticingBug
            bugImg1={mosquito1}
            bugImg2={mosquito2}
            bugImg3={mosquito3}
            bugTitle="Mosquitos"
            noticing={props.noticingOther}
            setNoticing={props.setNoticingOther}
          />
        </div>
        <div>
          <IQNoticingBug
            bugImg1={flea1}
            bugImg2={flea2}
            bugImg3={flea3}
            bugTitle="Fleas"
            noticing={props.noticingOther}
            setNoticing={props.setNoticingOther}
          />
        </div>
        <div>
          <IQNoticingBug
            bugImg1={fly1}
            bugImg2={fly2}
            bugImg3={fly3}
            bugTitle="Flys"
            noticing={props.noticingOther}
            setNoticing={props.setNoticingOther}
          />
        </div>
        <div>
          <IQNoticingBug
            bugImg1={tick1}
            bugImg2={tick2}
            bugImg3={tick3}
            bugTitle="Ticks"
            noticing={props.noticingOther}
            setNoticing={props.setNoticingOther}
          />
        </div>
        <div>
          <IQNoticingBug
            bugImg1={rodent1}
            bugImg2={rodent2}
            bugImg3={rodent3}
            bugTitle="Rodents"
            noticing={props.noticingOther}
            setNoticing={props.setNoticingOther}
          />
        </div>
      </div>
      <div className={navClasses.section_nav}>
        <div className={navClasses.div_grow}></div>
        <div className={navClasses.section_nav_right}>
          <img
            alt="next"
            src={buttonNext1}
            className={navClasses.section_nav_button}
            onClick={nextPage}
            onMouseOver={(e) => (e.currentTarget.src = buttonNext2)}
            onMouseOut={(e) => (e.currentTarget.src = buttonNext1)}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default IQNoticing;
