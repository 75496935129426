import React, { Fragment, useState } from 'react';
import { NavLink } from 'react-router-dom';

import classes from './Bug.module.css';

import Modal from './UI/Modal';

const Bug = (props) => {
  const [bugImgDisp, setBugImgDisp] = useState(props.bugImg1);
  const [modalIsShown, setModalIsShown] = useState(false);

  const showModalHandler = () => {
    setModalIsShown(true);
  };
  const hideModalHandler = () => {
    setModalIsShown(false);
  };

  const swapImg = () => {
    setBugImgDisp(props.bugImg2);
  };
  const restoreImg = () => {
    setBugImgDisp(props.bugImg1);
  };

  const modalContent = (
    <Fragment>
      <div className={classes.modal_main}>
        <div className={classes.modal_header}>
          <div className={classes.modal_bug_div}>
            <img
              src={props.bugImg3}
              alt={props.bugTitle}
              className={classes.modal_bug}
            />
          </div>
          <div className={classes.modal_bug_title}>{props.bugTitle}</div>
          <div className={classes.div_grow}></div>
          <div>
            <button className={classes.modal_button} onClick={hideModalHandler}>
              X
            </button>
          </div>
        </div>
        <div className={classes.modal_content}>{props.bugInfo}</div>
      </div>
      <div className={classes.modal_footer}>
        <center>
          <NavLink className={classes.button_nav} to="/instantquote">
            <div className={classes.button_container}>
              <div className={classes.button_free}>FREE</div>
              <div className={classes.button_content}>
                Online Instant Quote &gt;&gt;
              </div>
            </div>
          </NavLink>
        </center>
      </div>
    </Fragment>
  );

  return (
    <Fragment>
      <div
        id="bugDiv"
        className={classes.container}
        onMouseEnter={swapImg}
        onMouseLeave={restoreImg}
        onClick={showModalHandler}
      >
        <div>
          <center>
            <img
              src={bugImgDisp}
              alt={props.bugTitle}
              className={classes.bug}
            />
          </center>
        </div>
        <div className={classes.bug_title}>{props.bugTitle}</div>
      </div>
      {modalIsShown && <Modal onClose={hideModalHandler}>{modalContent}</Modal>}
    </Fragment>
  );
};

export default Bug;
