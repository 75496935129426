import React, { useState, useEffect } from 'react';

import classes from './IQOptionsCkbox.module.css';

import img1 from '../assets/checkboxes/unchecked-5.png';
import img2 from '../assets/checkboxes/ckrollover-5.png';
import img3 from '../assets/checkboxes/check-5.png';

const IQOptionsCkbox = (props) => {
  const [ckboxImgDisp, setCkboxImgDisp] = useState(img1);

  // set the ckbox image to selected if it had previously been selected and the user returned back to this step.
  useEffect(() => {
    if (props.addOns.includes(props.desc)) {
      setCkboxImgDisp(img3);
    }
  }, [props.addOns, props.desc]);

  const swapImg = () => {
    ckboxImgDisp !== img3 && setCkboxImgDisp(img2);
  };
  const restoreImg = () => {
    ckboxImgDisp !== img3 && setCkboxImgDisp(img1);
  };

  const ckboxHandler = () => {
    if (ckboxImgDisp === img3) {
      setCkboxImgDisp(img1);

      const tempAddOns = [...props.addOns].filter((el) => {
        return el !== props.desc;
      });

      props.setAddOns(tempAddOns);
    } else {
      setCkboxImgDisp(img3);

      const tempAddOns = [...props.addOns, props.desc].filter((el) => {
        return el !== null && el !== '';
      });

      props.setAddOns(tempAddOns);
    }
  };

  return (
    <div className={classes.ckbox_container}>
      <div>
        <img
          onMouseEnter={swapImg}
          onMouseLeave={restoreImg}
          onClick={ckboxHandler}
          src={ckboxImgDisp}
          alt={props.desc}
          className={classes.ckbox_img}
        />
      </div>
      <div className={classes.ckbox_desc}>
        {props.desc}
        {props.packageType === 'General Pest Service Plan' &&
          props.addOns.indexOf(props.desc) >= 0 &&
          props.addOns.indexOf(props.desc) <= 1 && (
            <span style={{ color: 'red', fontSize: '2.5rem' }}>
              &nbsp;&nbsp;*FREE
            </span>
          )}
      </div>
      <div className={classes.ckbox_price}>
        {props.packageType === 'General Pest Service Plan' &&
        props.addOns.indexOf(props.desc) >= 0 &&
        props.addOns.indexOf(props.desc) <= 1 ? (
          <span>$0</span>
        ) : (
          <span>${props.price}</span>
        )}
      </div>
    </div>
  );
};

export default IQOptionsCkbox;
