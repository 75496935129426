import React from 'react';

import classes from './HouseTreatments.module.css';

import houseImg from '../assets/house.png';
import house1 from '../assets/house-1.png';
import house2 from '../assets/house-2.png';
import house3 from '../assets/house-3.png';
import house4 from '../assets/house-4.png';
import house5 from '../assets/house-5.png';
import houseplus from '../assets/house-plus.png';

const HouseTreatments = () => {
  return (
    <div className={classes.container}>
      <div>
        <img
          src={houseImg}
          alt="Home Treatments"
          className={classes.houseimage}
        />
      </div>
      <div className={classes.desc_container}>
        <ul className={classes.house_list}>
          <li className={classes.house_list__li}>
            <div className={classes.house_list__li_div1}>
              <img
                src={house1}
                alt="Sweep and Dust Eaves"
                className={classes.house_num_img}
              />
              <h2>SWEEP & DUST EAVES</h2>
            </div>
            <div className={classes.house_list__li_div2}>
              Removes webs & insect/wasp nests applying a water-resistant dust
              along the eaves.
            </div>
          </li>
          <li className={classes.house_list__li}>
            <div className={classes.house_list__li_div1}>
              <img
                src={house2}
                alt="Entry Points, Cracks & Crevices"
                className={classes.house_num_img}
              />
              <h2>ENTRY-POINTS / CRACK & CREVICES</h2>
            </div>
            <div className={classes.house_list__li_div2}>
              Water-resistant barrier targeting cracks, crevices & entry points
              conducive to pest activity.
            </div>
          </li>
          <li className={classes.house_list__li}>
            <div className={classes.house_list__li_div1}>
              <img
                src={house3}
                alt="Perimeter Treatment"
                className={classes.house_num_img}
              />
              <h2>PERIMETER TREATMENT</h2>
            </div>
            <div className={classes.house_list__li_div2}>
              Seasonally tailored water-resistant perimeter that creates a
              barrier between your home & outside pests.
            </div>
          </li>
          <li className={classes.house_list__li}>
            <div className={classes.house_list__li_div1}>
              <img
                src={house4}
                alt="Lawn Granular Barrier"
                className={classes.house_num_img}
              />
              <h2>LAWN GRANULAR BARRIER</h2>
            </div>
            <div className={classes.house_list__li_div2}>
              Prevents pests from nesting near your home.
            </div>
          </li>
          <li className={classes.house_list__li}>
            <div className={classes.house_list__li_div1}>
              <img
                src={house5}
                alt="Interior Protection"
                className={classes.house_num_img}
              />
              <h2>INTERIOR PROTECTION</h2>
            </div>
            <div className={classes.house_list__li_div2}>
              Treats specific areas where pests are most likely to be attracted.
            </div>
          </li>
          <li className={classes.house_list__li}>
            <div className={classes.house_list__li_div1}>
              <img
                src={houseplus}
                alt="Customized Treatments"
                className={classes.house_num_img}
              />
              <h2>CUSTOMIZED TREATMENTS</h2>
            </div>
            <div className={classes.house_list__li_div2}>
              A customized property inspection can custom tailor other pest
              treatments that may be needed for your home.
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default HouseTreatments;
