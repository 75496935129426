import React, { Fragment } from 'react';

import IQOptionsCkbox from './IQOptionsCkbox';

import classes from './IQOptions.module.css';
import navClasses from './IQNav.module.css';

import { ArrowRightOutlined } from '@ant-design/icons';

import doorstepImg from '../assets/doorstep.jpg';
import doorstep2Img from '../assets/doorstep2.jpg';
import buttonBack1 from '../assets/buttons/back_1.png';
import buttonBack2 from '../assets/buttons/back_2.png';
import buttonScheduleNow1 from '../assets/buttons/submit_quote_request_1.png';
import buttonScheduleNow2 from '../assets/buttons/submit_quote_request_2.png';

const IQOptions = (props) => {
  let initialCharge = 0;
  let biMonthlyCharge = 0;

  if (props.houseSqFt === 'Under 2,000') {
    initialCharge = 319;
    biMonthlyCharge = 119;
  } else if (props.houseSqFt === '2,000 to 2,999') {
    initialCharge = 329;
    biMonthlyCharge = 129;
  } else if (props.houseSqFt === '3,000 to 3,999') {
    initialCharge = 339;
    biMonthlyCharge = 139;
  } else if (props.houseSqFt === '4,000 to 4,999') {
    initialCharge = 349;
    biMonthlyCharge = 149;
  } else if (props.houseSqFt === '5,000 to 5,999') {
    initialCharge = 359;
    biMonthlyCharge = 159;
  } else if (props.houseSqFt === '6,000 to 6,999') {
    initialCharge = 369;
    biMonthlyCharge = 169;
  } else if (props.houseSqFt === '7,000+') {
    initialCharge = 379;
    biMonthlyCharge = 179;
  } else {
    initialCharge = 349;
    biMonthlyCharge = 149;
  }

  const prevPage = () => {
    props.setDivDisplay('SquareFootage');
  };

  const nextPage = () => {
    props.setDivDisplay('PersonalInfo');
  };

  // Since we are not choosing between a service contract and one-time treatement, we will default to the service contract
  props.setPackageType('General Pest Service Plan');

  return (
    <Fragment>
      <div className={classes.container}>
        <div className={classes.container_img}>
          <img
            src={doorstepImg}
            className={classes.img_doorstep}
            alt="Doorstep"
          />
          <img
            src={doorstep2Img}
            className={classes.img_doorstep2}
            alt="Doorstep"
          />
        </div>
        <div className={classes.container_form}>
          <div className={classes.container_bullet}>
            General Pest Service Plan
          </div>
          <div className={classes.container_content}>
            <ArrowRightOutlined className={classes.arrow_symbol} />
            <div>
              Initial Charge of{' '}
              <span className={classes.text_pop_1}>${initialCharge}</span> now{' '}
              <span className={classes.text_pop_2}>$99</span> for new customers.
            </div>
          </div>
          {props.noticingGeneral.length > 0 && (
            <div className={classes.container_content}>
              <ArrowRightOutlined className={classes.arrow_symbol} />
              <div>
                Service includes treatment for{' '}
                {props.noticingGeneral.join(' / ')}
              </div>
            </div>
          )}
          <div className={classes.container_content}>
            <ArrowRightOutlined className={classes.arrow_symbol} />
            <div>
              Regular recurring services scheduled to keep your home pest free.
            </div>
          </div>
          <div className={classes.container_content}>
            <div className={classes.quoteblock}>
              Call us anytime you see an increase in pest activity. We will come
              out for&nbsp;
              <span className={classes.text_pop_3}>FREE</span>&nbsp;as part of
              your service.
            </div>
          </div>
          {props.noticingOther.length > 0 && (
            <div className={classes.container_content}>
              <ArrowRightOutlined className={classes.arrow_symbol} />
              <div>
                Pricing for additional services to treat{' '}
                {props.noticingOther.join(' / ')} will be discussed with your
                Service Pro Advocate. This pricing varies depending on yard size
                as well as severity of infestation.
              </div>
            </div>
          )}
          <div className={classes.section_title}>
            Add-Ons
            {props.packageType === 'General Pest 12 Month Service Contract' && (
              <span className={classes.section_title_subheader}>
                <br></br>Get up to 2 Add-Ons for FREE with your initial
                treatment!
              </span>
            )}
          </div>
          <div className={classes.container_ckboxes}>
            <div className={classes.container_ckbox}>
              <IQOptionsCkbox
                desc="Garage"
                price="49"
                addOns={props.addOns}
                setAddOns={props.setAddOns}
                packageType={props.packageType}
              />
            </div>
            <div className={classes.container_ckbox}>
              <IQOptionsCkbox
                desc="Termite Monitors"
                price="49"
                addOns={props.addOns}
                setAddOns={props.setAddOns}
                packageType={props.packageType}
              />
            </div>
            <div className={classes.container_ckbox}>
              <IQOptionsCkbox
                desc="Peak & Eave Spray"
                price="29"
                addOns={props.addOns}
                setAddOns={props.setAddOns}
                packageType={props.packageType}
              />
            </div>
            <div className={classes.container_ckbox}>
              <IQOptionsCkbox
                desc="Shed"
                price="29"
                addOns={props.addOns}
                setAddOns={props.setAddOns}
                packageType={props.packageType}
              />
            </div>
            <div className={classes.container_ckbox}>
              <IQOptionsCkbox
                desc="Weep Hole Injection"
                price="49"
                addOns={props.addOns}
                setAddOns={props.setAddOns}
                packageType={props.packageType}
              />
            </div>
            <div className={classes.container_ckbox}>
              <IQOptionsCkbox
                desc="Attic"
                price="49"
                addOns={props.addOns}
                setAddOns={props.setAddOns}
                packageType={props.packageType}
              />
            </div>
            <div className={classes.container_ckbox}>
              <IQOptionsCkbox
                desc="Pool Perimeter"
                price="29"
                addOns={props.addOns}
                setAddOns={props.setAddOns}
                packageType={props.packageType}
              />
            </div>
            <div className={classes.container_ckbox}>
              <IQOptionsCkbox
                desc="Playset"
                price="29"
                addOns={props.addOns}
                setAddOns={props.setAddOns}
                packageType={props.packageType}
              />
            </div>
          </div>
        </div>
      </div>

      <div className={navClasses.section_nav}>
        <div className={navClasses.section_nav_left}>
          <img
            alt="back"
            src={buttonBack1}
            className={navClasses.section_nav_button}
            onClick={prevPage}
            onMouseOver={(e) => (e.currentTarget.src = buttonBack2)}
            onMouseOut={(e) => (e.currentTarget.src = buttonBack1)}
          />
        </div>
        <div className={navClasses.div_grow}></div>
        <div className={navClasses.section_nav_right}>
          <img
            alt="Submit Request for Quote"
            src={buttonScheduleNow1}
            className={navClasses.section_nav_button}
            onClick={nextPage}
            onMouseOver={(e) => (e.currentTarget.src = buttonScheduleNow2)}
            onMouseOut={(e) => (e.currentTarget.src = buttonScheduleNow1)}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default IQOptions;
