import React from 'react';
import { isMobile } from 'react-device-detect';
import { NavLink } from 'react-router-dom';

import classes from './HomePhoto.module.css';

import backgroundVideo from '../assets/home-header.mp4';
import backgroundPhoto from '../assets/home-header.jpg';

import nextDayServiceImg from '../assets/nextdayservice.png';

const HomePhoto = () => {
  return (
    <div className={classes.anchorDiv}>
      <div className={classes.photo_header}>
        <ul className={classes.banner_text_container}>
          <li className={classes.banner_text_subtitle}>Keep your home</li>
          <li className={classes.banner_text_title}>
            Protected. Clean. Sustainable.
          </li>
          <li className={classes.banner_text_subtitle}>
            Professional Pest Control
          </li>
        </ul>
        <div className={classes.summer_special_section}>
          <NavLink className={classes.nav_link} to="/instantquote">
            <div className={classes.summer_special_section_button}>
              Get a Free Quote Now &gt;&gt;
            </div>
          </NavLink>
          <div className={classes.summer_special_section_text}>
            <div>Offering Summer Special!&nbsp;</div>
            <div className={classes.percent_off_text}>50% off</div>
          </div>
        </div>
        <div className={classes.flex_grow}>&nbsp;</div>
        <div>
          <img
            src={nextDayServiceImg}
            alt="Logo"
            className={classes.next_day_service}
          />
        </div>
      </div>
      {isMobile ? (
        <img
          src={backgroundPhoto}
          className={classes.backgroundphoto}
          alt="Home with Family"
        />
      ) : (
        <video className={classes.backgroundvideo} autoPlay loop muted>
          <source src={backgroundVideo} type="video/mp4" />
        </video>
      )}
    </div>
  );
};

export default HomePhoto;
