import React from 'react';
import Nav from './Nav';

const FAQ = () => {
  return (
    <div>
      <div>
        <Nav />
      </div>
    </div>
  );
};

export default FAQ;
