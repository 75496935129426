import React, { useState, useEffect } from 'react';

import radio1 from '../assets/radio1.png';
import radio2 from '../assets/radio2.png';
import radio3 from '../assets/radio3.png';

import classes from './RadioButton.module.css';

const RadioButton = (props) => {
  const [radioImgDisp, setRadioImgDisp] = useState(radio1);

  // set the radio image based on a change in the state variable.
  useEffect(() => {
    if (props.statefulVar === props.optionDesc) {
      setRadioImgDisp(radio3);
    } else {
      setRadioImgDisp(radio1);
    }
  }, [props.statefulVar, props.optionDesc]);

  const swapImg = () => {
    radioImgDisp !== radio3 && setRadioImgDisp(radio2);
  };
  const restoreImg = () => {
    radioImgDisp !== radio3 && setRadioImgDisp(radio1);
  };

  const ckRadioHandler = () => {
    setRadioImgDisp(radio3);
    props.setStatefulVar(props.optionDesc);
  };

  return (
    <div
      className={classes.radio_container}
      onMouseEnter={swapImg}
      onMouseLeave={restoreImg}
      onClick={ckRadioHandler}
    >
      <div>
        <img
          src={radioImgDisp}
          alt="Radio Button"
          className={classes.radio_img_button}
        />
      </div>
      <div className={classes.radio_desc}>{props.optionDesc}</div>
    </div>
  );
};

export default RadioButton;
