import React from 'react';
import { useNavigate } from 'react-router-dom';

import classes from './IQSubmitted.module.css';

const IQSubmitted = (props) => {
  // FUNCTION TO ROUTE BACK TO HOMEPAGE ON BUTTON CLICK
  let navigate = useNavigate();
  const routeToHome = () => {
    let path = '/home';
    navigate(path);
  };

  return (
    <div className={classes.container}>
      <div className={classes.title}>Your information has been received!</div>
      <div className={classes.content}>
        One of our advocates will reach out to you to finalize your scheduled
        appointment.
      </div>
      <div className={classes.button_container}>
        <button onClick={routeToHome}>Done</button>
      </div>
    </div>
  );
};

export default IQSubmitted;
