import React, { Fragment } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';

import Home from './components/Home';
import FAQ from './components/FAQ';
import ServiceAreas from './components/ServiceAreas';
import AboutUs from './components/AboutUs';
import InstantQuote from './components/InstantQuote';
import NotFound from './components/NotFound';
import DbQueryExamples from './components/x-db-queries';

function App() {
  return (
    <Fragment>
      <Routes>
        <Route path="/" element={<Navigate replace to="/home" />} />
        <Route path="/home" element={<Home />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/service" element={<ServiceAreas />} />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/instantquote" element={<InstantQuote />} />
        <Route path="/dbqueries" element={<DbQueryExamples />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Fragment>
  );
}

export default App;
