import React, { useState, useEffect } from 'react';

import classes from './IQNoticingBug.module.css';

const IQNoticingBug = (props) => {
  const [bugImgDisp, setBugImgDisp] = useState(props.bugImg1);

  // set the bug image to selected if it had previously been selected and the user returned back to this step.
  useEffect(() => {
    if (props.noticing.includes(props.bugTitle)) {
      setBugImgDisp(props.bugImg3);
    }
  }, [props.noticing, props.bugTitle, props.bugImg3]);

  const swapImg = () => {
    bugImgDisp !== props.bugImg3 && setBugImgDisp(props.bugImg2);
  };
  const restoreImg = () => {
    bugImgDisp !== props.bugImg3 && setBugImgDisp(props.bugImg1);
  };

  const selectBugHandler = () => {
    if (bugImgDisp === props.bugImg3) {
      setBugImgDisp(props.bugImg1);

      const tempNoticing = [...props.noticing].filter((el) => {
        return el !== props.bugTitle;
      });
      props.setNoticing(tempNoticing);
    } else {
      setBugImgDisp(props.bugImg3);

      const tempNoticing = [...props.noticing, props.bugTitle].filter((el) => {
        return el !== null && el !== '';
      });

      props.setNoticing(tempNoticing);
    }
    //    bugImgDisp === props.bugImg3
    //      ? setBugImgDisp(props.bugImg2)
    //      : setBugImgDisp(props.bugImg3);
  };

  return (
    <div id="bugDiv" className={classes.container}>
      <div>
        <center>
          <img
            onMouseEnter={swapImg}
            onMouseLeave={restoreImg}
            onClick={selectBugHandler}
            src={bugImgDisp}
            alt={props.bugTitle}
            className={classes.bug}
          />
        </center>
      </div>
      <div className={classes.bug_title}>{props.bugTitle}</div>
    </div>
  );
};

export default IQNoticingBug;
