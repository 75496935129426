import React from 'react';
import { NavLink } from 'react-router-dom';
import classes from './Home.module.css';

import HomePhoto from './HomePhoto';
import HouseTreatmentsHeader from './HouseTreatmentsHeader';
import HouseTreatments from './HouseTreatments';
import BugsInfo from './BugsInfo';
import HomeAskAQuestion from './HomeAskAQuestion';
import HomeCallbackTime from './HomeCallbackTime';

import logoImage from '../assets/logo.png';
import shieldImage from '../assets/logo-symbol-large.png';

const Home = () => {
  return (
    <div className={classes.page_container}>
      <div className={classes.logo_header}>
        <img src={logoImage} alt="Logo" className={classes.logo_top} />
        <div className={classes.div_grow}></div>
        <NavLink className={classes.button_nav} to="/instantquote">
          <div className={classes.button_container}>
            <div className={classes.button_free}>FREE</div>
            <div className={classes.button_content}>
              Online Instant Quote &gt;&gt;
            </div>
          </div>
        </NavLink>
      </div>
      <div>
        <HomePhoto />
      </div>
      <div>
        <HouseTreatmentsHeader />
      </div>
      <div>
        <HouseTreatments />
      </div>
      <div>
        <BugsInfo />
      </div>
      <div className={classes.footer}>
        <div
          className={`${classes.div_33_percent} ${classes.div_question_background}`}
        >
          <HomeAskAQuestion />
        </div>
        <div
          className={`${classes.div_33_percent} ${classes.div_callback_background}`}
        >
          <HomeCallbackTime />
        </div>
        <div
          className={`${classes.div_33_percent} ${classes.div_grow} ${classes.div_bottomlogo_background}`}
        >
          <img src={shieldImage} alt="Logo" className={classes.logo_bottom} />
          <NavLink className={classes.button_nav} to="/instantquote">
            <div className={classes.button_container}>
              <div className={classes.button_free}>FREE</div>
              <div className={classes.button_content}>
                Online Instant Quote &gt;&gt;
              </div>
            </div>
          </NavLink>
        </div>
      </div>
      <div className={classes.rights_reserved}>
        &copy; 2023 All Rights Reserved.
      </div>
    </div>
  );
};

export default Home;
