import React, { Fragment } from 'react';

import RadioButton from './RadioButton';

import classes from './IQSquareFootage.module.css';
import navClasses from './IQNav.module.css';

import housesqft from '../assets/house-sqft.png';
import buttonNext1 from '../assets/buttons/next_1.png';
import buttonNext2 from '../assets/buttons/next_2.png';
import buttonBack1 from '../assets/buttons/back_1.png';
import buttonBack2 from '../assets/buttons/back_2.png';

const IQSquareFootage = (props) => {
  const prevPage = () => {
    props.setDivDisplay('House');
  };

  const nextPage = () => {
    props.setDivDisplay('Options');
  };

  return (
    <Fragment>
      <div className={navClasses.section_question}>
        What is the size of your home?
      </div>
      <div className={navClasses.section_title}>Square Footage</div>
      <div className={classes.container}>
        <div>
          <img src={housesqft} alt="House" className={classes.img_housesqft} />
        </div>
        <div className={classes.container_radio}>
          <RadioButton
            optionDesc="Under 2,000"
            statefulVar={props.houseSqFt}
            setStatefulVar={props.setHouseSqFt}
          />
          <RadioButton
            optionDesc="2,000 to 2,999"
            statefulVar={props.houseSqFt}
            setStatefulVar={props.setHouseSqFt}
          />
          <RadioButton
            optionDesc="3,000 to 3,999"
            statefulVar={props.houseSqFt}
            setStatefulVar={props.setHouseSqFt}
          />
          <RadioButton
            optionDesc="4,000 to 4,999"
            statefulVar={props.houseSqFt}
            setStatefulVar={props.setHouseSqFt}
          />
          <RadioButton
            optionDesc="5,000 to 5,999"
            statefulVar={props.houseSqFt}
            setStatefulVar={props.setHouseSqFt}
          />
          <RadioButton
            optionDesc="6,000 to 6,999"
            statefulVar={props.houseSqFt}
            setStatefulVar={props.setHouseSqFt}
          />
          <RadioButton
            optionDesc="7000+"
            statefulVar={props.houseSqFt}
            setStatefulVar={props.setHouseSqFt}
          />
        </div>
      </div>
      <div className={navClasses.section_nav}>
        <div className={navClasses.section_nav_left}>
          <img
            alt="back"
            src={buttonBack1}
            className={navClasses.section_nav_button}
            onClick={prevPage}
            onMouseOver={(e) => (e.currentTarget.src = buttonBack2)}
            onMouseOut={(e) => (e.currentTarget.src = buttonBack1)}
          />
        </div>
        <div className={navClasses.div_grow}></div>
        <div className={navClasses.section_nav_right}>
          {props.houseSqFt.length > 0 ? (
            <img
              alt="next"
              src={buttonNext1}
              className={navClasses.section_nav_button}
              onClick={nextPage}
              onMouseOver={(e) => (e.currentTarget.src = buttonNext2)}
              onMouseOut={(e) => (e.currentTarget.src = buttonNext1)}
            />
          ) : (
            <img
              alt="next"
              src={buttonNext1}
              className={navClasses.section_nav_button_disabled}
            />
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default IQSquareFootage;
